.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 4em;
  margin-left: auto;
  margin-right: auto;
  color: white;
  font-size: 0.8em;
  line-height: 1em;
  z-index: -1;
}

.push {
  height: 2em;
}

.hidden {
  display: none;
}

@media only screen and (max-width: 600px) {
  .footer {
    margin-top: 12em;
    margin-left: auto;
    margin-right: auto;

    line-height: 0.9em;
    font-size: 0.65em;
  }
}
